import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store';
import App from './App';

import './scss/index.scss';

render(
	<Provider store={store}>
		<PersistGate
			loading={null}
			persistor={persistor}
		>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
