import React from 'react';
import Main from './components/Main';

import { BrowserRouter } from 'react-router-dom';

const App = () => (
	<BrowserRouter>
		<Main />
	</BrowserRouter>
);

export default App;
