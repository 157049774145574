import Footer from './Footer';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { bindActionCreators } from 'redux';
import { toggleLogin } from '../../../actions/auth';
const mapStateToProps = (state) => {
	const showLogin = _get(state, 'auth.showLogin', false);
	const vendorName = _get(state, 'auth.vendor.name', {});
	const footerVendorLogo = _get(state, 'auth.vendor.footerVendorLogo', {});
	const youtubeUrl = _get(state, 'auth.vendor.youtubeUrl', {});
	const facebookUrl = _get(state, 'auth.vendor.facebookUrl', {});
	const linkedinUrl = _get(state, 'auth.vendor.linkedinUrl', {});
	const instagramUrl = _get(state, 'auth.vendor.instagramUrl', {});

	return {
		showLogin,
		vendorName,
		footerVendorLogo,
		youtubeUrl,
		facebookUrl,
		linkedinUrl,
		instagramUrl,
	};
};
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			toggleLogin,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
